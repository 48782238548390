// Colors map
$mgt-platforms-colors: (
  mrt: var(--rs-color--platform--mrt),
  mrt_fr: var(--rs-color--platform--mrt),
  rei: var(--rs-color--platform--rei),
  fms: var(--rs-color--platform--fms),
  mgt: var(--rs-color--platform--mgt),
  divc: var(--rs-color--platform--other),
  ord: var(--rs-color--platform--ord),
);

// Generating color utilities classes
:root {
  @each $color-key, $color-value in $mgt-platforms-colors {
    // generate color variables
    --#{$color-key}: #{$color-value};

    // generate color classes
    .#{$color-key}-color {
      color: $color-value !important;
    }

    // generate color classes
    .#{$color-key}-background-color {
      background-color: $color-value !important;
    }
  }
}
